<template>
  <div id="app">
    <div id="sidebar">
      <header>
        <router-link to="/"
          >ZREO Archive<b-icon
            icon="list"
            id="menuicon"
            v-on:click="displayMenu()"
            font-scale="1"
        /></router-link>
      </header>
      <ul class="nav" id="nav" v-on:click="displayMenuWidth()">
        <li>
          <router-link to="/legend-of-zelda">The Legend of Zelda</router-link>
        </li>
        <li>
          <router-link to="/adventure-of-link"
            >The Adventure of Link</router-link
          >
        </li>
        <li>
          <router-link to="/link-to-the-past">A Link to the Past</router-link>
        </li>
        <li>
          <router-link to="/links-awakening">Link's Awakening</router-link>
        </li>
        <li>
          <router-link to="/ocarina-of-time">Ocarina of Time</router-link>
        </li>
        <li>
          <router-link to="/majoras-mask">Majora's Mask</router-link>
        </li>
        <li>
          <router-link to="/oracle-of-ages">Oracle of Ages</router-link>
        </li>
        <li>
          <router-link to="/oracle-of-seasons">Oracle of Seasons</router-link>
        </li>
        <li>
          <router-link to="/wind-waker">Wind Waker</router-link>
        </li>
        <li>
          <router-link to="/four-swords-adventure">Four Swords Adventure</router-link>
        </li>
        <li>
          <router-link to="/minish-cap">The Minish Cap</router-link>
        </li>
        <li>
          <router-link to="/twilight-princess">Twilight Princess</router-link>
        </li>
        <li>
          <router-link to="/phantom-hourglass">Phantom Hourglass</router-link>
        </li>
        <li>
          <router-link to="/spirit-tracks">Spirit Tracks</router-link>
        </li>
        <li>
          <router-link to="/skyward-sword">Skyward Sword</router-link>
        </li>
        <li>
          <router-link to="/soundscape-vol-1">Soundscape Vol. 1</router-link>
        </li>
        <li>
          <router-link to="/soundscape-vol-2">Soundscape Vol. 2</router-link>
        </li>
        <li>
          <router-link to="/soundscape-vol-3">Soundscape Vol. 3</router-link>
        </li>
        <li>
          <router-link to="/open-symphony">Open Symphony</router-link>
        </li>
        <li>
          <router-link to="/battlescapes">Battlescapes</router-link>
        </li>
        <li>
          <router-link to="/twilight-symphony">Twilight Symphony</router-link>
        </li>
      </ul>
      <span id="footer">Zelda and its games are a trademark of Nintendo.</span>
    </div>
    <router-view />
  </div>
</template>

<style>
body {
  overflow-x: hidden;
  font-size: 16px;
  background-image: url(./assets/bg_big.png);
}

#sidebar {
  z-index: 1000;
  position: fixed;
  left: 250px;
  width: 250px;
  height: 100%;
  margin-left: -250px;
  overflow-y: auto;
  background: #37474f;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

#sidebar #menuicon {
  display: none;
  width: 0px;
  height: 0px;
  float: right;
  color: #2da860;
}

@media screen and (max-width: 768px) {
  #sidebar {
    z-index: 1000;
    position: fixed;
    width: 100%;
    height: 52px;
    overflow-y: auto;
    background: #37474f;
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    transition: all 0.5s ease;
  }

  #sidebar #menuicon {
    float: right;
    height: 52px;
    width: 52px;
    display: block;
  }

  #sidebar header a {
    margin-left: 52px;
  }

  #sidebar #nav {
    display: none;
    height: 0%;
    position: absolute;
    width: 100%;
  }

  #sidebar #footer {
    display: none;
  }
}

#sidebar header {
  background-color: #263238;
  font-size: 28px;
  line-height: 52px;
  text-align: center;
}

#sidebar header a {
  color: #2da860;
  display: block;
  text-decoration: none;
}

#sidebar header a:hover {
  color: #cfd8dc;
}

#sidebar li {
  width: 100%;
  border-bottom: 1px solid #455a64;
}

#sidebar li:hover {
  border-bottom: 1px solid #2da860;
}

#sidebar .nav a {
  background: none;
  display: inline-block;
  color: #cfd8dc;
  font-size: 18px;
  width: 100%;
  height: 100%;
  padding-bottom: 10px;
  padding-top: 10px;
}

#sidebar .nav a:hover {
  background: none;
  color: #2da860;
  text-decoration: none;
}

#sidebar .nav .router-link-active {
  color: #d4ce46;
}

#sidebar #footer {
  padding-left: 10px;
  padding-right: 10px;
  left: 250px;
  padding-bottom: 10px;
  font-size: 12px;
  margin-left: -250px;
  position: absolute;
  align-self: flex-end;
  color: #cfd8dc;
}

#app {
  font-family: Zelda, Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

@font-face {
  font-family: Zelda;
  src: url(./assets/SherwoodRegular.ttf);
}
</style>


<script>
export default {
  mounted() {
    window.addEventListener("resize", this.displayMenuWidth.bind(this));
  },
  methods: {
    displayMenuWidth() {
      if (window.innerWidth > 768) {
        this.displayMenu(false);
      } else {
        this.displayMenu(true);
      }
    },

    displayMenu(wanted) {
      if (wanted !== undefined) {
        this.displayMenuActive = wanted;
      }

      if (this.displayMenuActive) {
        document.getElementById("nav").style.display = "none";
        document.getElementById("sidebar").style.height = "52px";
        this.displayMenuActive = false;
      } else {
        document.getElementById("nav").style.display = "block";
        document.getElementById("sidebar").style.height = "100%";
        this.displayMenuActive = true;
      }
    },
  },
};
</script>