import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/legend-of-zelda',
    name: 'The Legend of Zelda',
    component: () => import(/* webpackChunkName: "about" */ '../views/LegendOfZelda.vue')
  },
  {
    path: '/adventure-of-link',
    name: 'The Adventure of Link',
    component: () => import(/* webpackChunkName: "about" */ '../views/AdventureOfLink.vue')
  },
  {
    path: '/link-to-the-past',
    name: 'A Link to the Past',
    component: () => import(/* webpackChunkName: "about" */ '../views/LinkToThePast.vue')
  },
  {
    path: '/links-awakening',
    name: 'Link\'s Awakening',
    component: () => import(/* webpackChunkName: "about" */ '../views/LinksAwakening.vue')
  },
  {
    path: '/ocarina-of-time',
    name: 'Ocarina of Time',
    component: () => import(/* webpackChunkName: "about" */ '../views/OcarinaOfTime.vue')
  },
  {
    path: '/majoras-mask',
    name: 'Majora\'s Mask',
    component: () => import(/* webpackChunkName: "about" */ '../views/MajorasMask.vue')
  },
  {
    path: '/oracle-of-ages',
    name: 'Oracle of Ages',
    component: () => import(/* webpackChunkName: "about" */ '../views/OracleOfAges.vue')
  },
  {
    path: '/oracle-of-seasons',
    name: 'Oracle of Seasons',
    component: () => import(/* webpackChunkName: "about" */ '../views/OracleOfSeasons.vue')
  },
  {
    path: '/wind-waker',
    name: 'Wind Waker',
    component: () => import(/* webpackChunkName: "about" */ '../views/WindWaker.vue')
  },
  {
    path: '/four-swords-adventure',
    name: 'Four Swords Adventure',
    component: () => import(/* webpackChunkName: "about" */ '../views/FourSwordsAdventure.vue')
  },
  {
    path: '/minish-cap',
    name: 'The Minish Cap',
    component: () => import(/* webpackChunkName: "about" */ '../views/MinishCap.vue')
  },
  {
    path: '/twilight-princess',
    name: 'Twilight Princess',
    component: () => import(/* webpackChunkName: "about" */ '../views/TwilightPrincess.vue')
  },
  {
    path: '/phantom-hourglass',
    name: 'Phantom Hourglass',
    component: () => import(/* webpackChunkName: "about" */ '../views/PhantomHourglass.vue')
  },
  {
    path: '/spirit-tracks',
    name: 'Spirit Tracks',
    component: () => import(/* webpackChunkName: "about" */ '../views/SpiritTracks.vue')
  },
  {
    path: '/skyward-sword',
    name: 'Skyward Sword',
    component: () => import(/* webpackChunkName: "about" */ '../views/SkywardSword.vue')
  },
  {
    path: '/soundscape-vol-1',
    name: 'Soundscape Vol. 1',
    component: () => import(/* webpackChunkName: "about" */ '../views/SoundscapeVol1.vue')
  },
  {
    path: '/soundscape-vol-2',
    name: 'Soundscape Vol. 2',
    component: () => import(/* webpackChunkName: "about" */ '../views/SoundscapeVol2.vue')
  },
  {
    path: '/soundscape-vol-3',
    name: 'Soundscape Vol. 3',
    component: () => import(/* webpackChunkName: "about" */ '../views/SoundscapeVol3.vue')
  },
  {
    path: '/open-symphony',
    name: 'Open Symphony',
    component: () => import(/* webpackChunkName: "about" */ '../views/OpenSymphony.vue')
  },
  {
    path: '/battlescapes',
    name: 'Battlescapes',
    component: () => import(/* webpackChunkName: "about" */ '../views/Battlescapes.vue')
  },
  {
    path: '/twilight-symphony',
    name: 'Twilight Symphony',
    component: () => import(/* webpackChunkName: "about" */ '../views/TwilightSymphony.vue')
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

// Set Page Title
const DEFAULT_TITLE = 'ZREO Archvie';
router.afterEach((to) => {

  Vue.nextTick(() => {
    document.title = to.name + " | " + DEFAULT_TITLE || DEFAULT_TITLE;
  });

});

export default router
