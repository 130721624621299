import Vue from 'vue'
import App from './App.vue'
import router from './router'
import 'bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css'
import { BootstrapVueIcons, BIcon } from 'bootstrap-vue'
Vue.use(BootstrapVueIcons);
Vue.component('BIcon', BIcon);

Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
