<template>
  <div class="home">
    <h1>Welcome to the ZREOMusic Archive</h1>
    <img alt="Vue logo" src="../assets/covers/home.jpg" />
    <p>
      This archive is for the now gone
      <a href="http://zreomusic.com" target="_blank">ZREOMusic.com</a>.
      ZREOMusic orchestrated all the songs featured on this site as a
      reorchestrated version from the Zelda games made by Nintendo. All these
      songs are free to download and use as per ZREOMusic as long as it is for
      Non-Commercial use, more info can be found
      <a
        href="https://web.archive.org/web/20130306014805/http://zreomusic.com/faq"
        target="_blank"
        >here</a
      >.
    </p>
  </div>
</template>

<script>
export default {
  name: "Home",
};
</script>

<style scoped>
.home {
  border-bottom: 1px solid #455a64;
  border-radius: 5px;
  margin-top: 52px;
  margin-bottom: 52px;
  padding-top: 10px;
  display: inline-block;
  overflow: none;
  width: 550px;
  background-color: #263238;
  color: #cfd8dc;
  box-shadow: 0 0 50px #263238;
  margin-left: 250px;
  padding-bottom: 10px;
}

@media screen and (max-width: 768px) {

  .home {
    border-bottom: 1px solid #455a64;
    border-radius: 0px;
    margin-top: 52px;
    margin-bottom: 52px;
    margin-left: auto;
    margin-right: auto;
    padding-top: 10px;
    display: inline-block;
    overflow: none;
    width: 550px;
    background-color: #263238;
    color: #cfd8dc;
    box-shadow: 0 0 50px #263238;
    padding-bottom: 10px;
  }


  
}

.home img {
  width: 500px;
  border-radius: 5px;
  box-shadow: 0 0 10px #455a64;
  margin-top: 20px;
}

.home p {
  margin: 50px;
}

.home a {
  color: #2da860;
}
</style>